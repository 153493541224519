<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Briefing</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <!-- <CInput
                    label="topicName"
                    v-model="briefing.selectedTopics.topicName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Names'"
                  
                  /> -->
                  <div v-if="mode.newMode">
                    <MultiSelect
                      label="Groups "
                      :options="allGroups"
                      @input="updateGroups"
                      :hide-selected="false"
                      :searchable="true"
                      optionLabel="groupName"
                      :disabled="mode.viewMode"
                      track-by="groupId"
                      :track-ids.sync="groupId"
                      idParamName="groupId"
                      :itemTypeisString="false"
                      invalidFeedback="Select atleast one Group"   
                        
                    ></MultiSelect>
                    <MultiSelect
                      label="Sub Groups"
                      :options="selectedSubGroups"
                      @input="updatedSubGroups"
                      :hide-selected="false"
                      :searchable="true"
                      optionLabel="subGroupName"
                      :disabled="mode.viewMode"
                      track-by="subGroupId"
                      :track-ids.sync="subgroupId"
                      idParamName="subGroupId"
                      :itemTypeisString="false"
                      invalidFeedback="Select atleast one Sub Group"
                    ></MultiSelect>
                    <!-- </div>
                  <div> -->

                    <MultiSelect
                      label="Brief Names"
                      :options="selectedBriefNames"
                      @input="updatedBriefNames"
                      :hide-selected="false"
                      :searchable="true"
                      optionLabel="briefName"
                      :disabled="mode.viewMode"
                      track-by="briefId"
                      :track-ids.sync="briefing.briefId"
                      idParamName="briefId"
                      :itemTypeisString="false"
                      invalidFeedback="Select atleast one Brief Names"
                    ></MultiSelect>

                    <MultiSelect
                      label="Brief Topics"
                      :options="selectedBriefTopics"
                      :hide-selected="false"
                      :searchable="true"
                      optionLabel="topicName"
                      :disabled="mode.viewMode"
                      track-by="topicId"
                      :track-ids.sync="viewselectedBriefTopics"
                      idParamName="topicId"
                      :itemTypeisString="false"
                      invalidFeedback="Select atleast one Brief Topic"
                    ></MultiSelect>
                    <MultiSelect
                      label="Departments"
                      :options="selectedDepartments"
                      :hide-selected="false"
                      :searchable="true"
                      optionLabel="departmentName"
                      :disabled="mode.viewMode"
                      track-by="departmentId"
                      :track-ids.sync="briefing.departmentId"
                      idParamName="departmentId"
                      :itemTypeisString="false"
                      invalidFeedback="Select atleast one Departments"
                    ></MultiSelect>
                  </div>

                  <div v-if="mode.viewMode || mode.editMode">
                   
                    <div v-if="briefing.otherBriefName == null || briefing.otherBriefName != null">
                      <MultiSelect
                        label="Brief Names"
                        :options="allBriefNames"
                        @input="updatedBriefNames"
                        :hide-selected="false"
                        :searchable="true"
                        optionLabel="briefName"
                        :disabled="mode.viewMode"
                        track-by="briefId"
                        :track-ids.sync="briefing.briefId"
                        idParamName="briefId"
                        :itemTypeisString="false"
                        invalidFeedback="Select atleast one Brief Names"
                      ></MultiSelect>
                      <div v-if="briefing.otherBriefName != null || briefing.otherBriefName == null">
                      <CInput
                        label="Other Brief Names"
                        v-model="briefing.otherBriefName"
                        horizontal
                        :disabled="mode.viewMode"
                        :invalid-feedback="'Please Enter the Names'" 
                      />
                    </div>
                      <MultiSelect
                        label="Brief Topics"
                        :options="allBriefTopics"
                        :hide-selected="false"
                      
                        :searchable="true"
                        optionLabel="topicName"
                        :disabled="mode.viewMode"
                        track-by="topicId"
                        :track-ids.sync="viewselectedBriefTopics"
                        idParamName="topicId"
                        :itemTypeisString="false"
                        invalidFeedback="Select atleast one Brief Topic"
                      ></MultiSelect>
                    </div>

                    <div v-if="briefing.departmentId != null">
                      <MultiSelect
                        label="Departments"
                        :options="allDepartments"
                        :hide-selected="false"
                        :searchable="true"
                        optionLabel="departmentName"
                        :disabled="mode.viewMode"
                        track-by="departmentId"
                        :track-ids.sync="briefing.departmentId"
                        idParamName="departmentId"
                        :itemTypeisString="false"
                        invalidFeedback="Select atleast one Departments"
                      ></MultiSelect>
                    </div>
                  </div>
                  <CInput
                    label="Date *"
                    v-model="briefing.date"
                    horizontal
                    type="date"
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Date'"
                    :is-valid="
                      $v.briefing.date.$dirty ? !$v.briefing.date.$error : null
                    "
                    @blur="$v.briefing.date.$touch()"
                  />
                  <CInput
                    label="Time *"
                    v-model="briefing.time"
                    horizontal
                    type="time"
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Time'"
                    :is-valid="
                      $v.briefing.time.$dirty ? !$v.briefing.time.$error : null
                    "
                    @blur="$v.briefing.time.$touch()"
                  />

                  <CTextarea
                    label="Information *"
                    v-model="briefing.information"
                    horizontal
                    rows="6"
                    :disabled="mode.viewMode"
                    :is-valid="
                      $v.briefing.information.$dirty
                        ? !$v.briefing.information.$error
                        : null
                    "
                    @blur="$v.briefing.information.$touch()"
                  >
                    <template #description>
                      <CRow>
                        <CCol md="12">
                          <small
                            style="float: right"
                            class="form-text text-muted"
                            ></small
                          >
                        </CCol>
                      </CRow>
                    </template>
                  </CTextarea>

                  <!--  <div class="form-group row">
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                    >
                      Status *</label
                    >
                     <div class="col-sm-9">
                      <select
                        class="form-control"
                        v-model="briefing.status"
                        :disabled="mode.viewMode"
                      >
                        <option disabled value="">--Select One Option--</option>
                        <option value="1">Upcoming</option>
                        <option value="2">Pending</option>
                        <option value="3">Past</option>
                      </select>
                    </div>
                  </div> -->

                  <CInput
                    label=" Website Link "
                    v-model="briefing.websiteLink"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Link'"
                  />
                  <CInput
                    label="Video Link "
                    v-model="briefing.videoLink"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Link'"
                  />
                  <div v-if="mode.newMode || mode.editMode" >
                    <MultiSelect
                    
                      label="Officer *"
                      v-model="briefing.officerId"
                      :close-on-select="true"
                      :options="allOfficers"
                      :hide-selected="false"
                      :searchable="true"
                     optionLabel="fullName"
                      :disabled="mode.viewMode"
                      :custom-label="officerdetails"
                      track-by="uid"
                      :track-ids.sync="briefing.officerId"
                      idParamName="uid"
                     
                      :itemTypeisString="false"
                      :invalid-feedback="'Select atleast one officer'"  
                    ></MultiSelect>
                    <p v-if="errors.length" class="p-error">
								<span v-for="error in errors"><span 
									v-if="error.field == 'uid'" style="color: red">{{ error.error }}</span></span>
							</p>
                   
                  </div>
                  <div v-if="mode.viewMode">
                    <CInput
                      label="Officer"
                      v-model="briefing.officerFirstName"
                      horizontal
                      :disabled="mode.viewMode"
                      :invalid-feedback="'Please Enter the Link'"
                    />
                  </div>
                  <!-- <div>
                  <multiselect  
                    :close-on-select="true"
                    :options="allOfficers"
                    :hide-selected="false"
                    :searchable="true"
                    optionLabel = "firstName"
                    v-model="briefing.officerId"
                    :disabled="mode.viewMode"
                    :custom-label="officerdetails"
                    track-by="uid"
                    :track-ids.sync="briefing.officerId"
                    idParamName="uid"
                    :itemTypeisString="false"
								> </multiselect>
                  </div> -->
                  <div
                    class="form-group row"
                    
                  >
                    <label
                      for="example-text-input text-right"
                      class="col-sm-3 col-form-label"
                    >
                      Attachment </label
                    >
                    <div class="col-sm-9">
                      <CInputFile
                        horizontal
                        type="file"
                        :disabled="mode.viewMode"
                        @change="uploadImage"
                      
                      />
                      <CRow>
                    <CCol md="3"></CCol>
                    <CCol md="9" align="center">
                      <CCard
                        v-if="briefing.imageId "
                        style="margin-top: 20px"
                      >
                        <CCardBody align="center">
                          <CButton
                            color="danger"
                            style="float: right"
                            @click="removeimage()"
                          >
                            <CIcon
                              name="cilXCircle"
                              description="Delete"
                            ></CIcon>
                          </CButton>
                          <CImg
                            v-if="briefing.imageId"
                            :src="imageUrl(briefing.imageId)"
                            height="180px"
                            alt="Responsive image"
                            :disabled="mode.viewMode"
                          > </CImg>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                    </div>
                  </div>
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="warning"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="Save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import MultiSelect from "../../containers/MultiSelect";
// var disabledFlag = false;
// Vue.component("v-select", VueSelect.VueSelect);

import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Briefing",
  components: {
    MultiSelect,
    datetime,
  },
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      i:"0",
      briefingId: "",
      briefing: {
        groupId:"",
        briefingId: "0",
        briefId: "",
      
        fullName: "",
       
        // topicId: "",
        // topicName: "",
        //topic:"",
        officerFirstName: "",
        firstName: "",
        lastName: "",
        date: "",
        time: "",
        imageId: "",
        status: "1",
        videoLink: "",
        websiteLink: "",
        information: "",
        officerId: "",
        otherBriefName: "",
        departmentName: "",
        briefName:"",
        topicId:[],
        selectedTopics: [
          {
            briefTopicId: "",
            briefingId: "",
            topicId: ""
          },
        ],
        departmentId: "",
      },
      viewselectedBriefTopics: [],
      newElement: [],
      groupId: "",
      subgroupId: "",
      allTopics: [],
      allOfficers: [],
      allGroups: [],
      selectedGroups: [],
      allSubGroups: [],
      selectedSubGroups: [],
      allBriefNames: [],
      selectedBriefNames: [],
      allBriefTopics: [],
      selectedBriefTopics: [],
      selectedDepartments: [],
      allDepartments: [],
      updateSubGroups: [],
      selectedSubGroupId: "",
      errors:[], 
      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {

    briefing: {
      date: {
        required,
      },
      time: {
        required,
      },
      information: {
        required,
      },
      officerId : {
        required,
      }
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.getGroups();
      this.getSubGroups();
      this.getBriefNames();
      this.getBriefTopics();
      this.getDepartments();
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.briefingId = this.$route.params.briefingId;
      this.getUser();
      //this.getBriefings();
      this.getGroups();
      this.getSubGroups();
      this.getBriefNames();
      this.getBriefTopics();
      this.getDepartments();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.briefingId = this.$route.params.briefingId;
      this.getUser();
      //this.getBriefings();
      this.getGroups();
      this.getSubGroups();
      this.getBriefNames();
      this.getBriefTopics();
      this.getDepartments();
    }
  },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({ name: "Briefings" });
    },
  
    updateGroups(data) {
      var self = this;
      console.log("data from group", data);
      self.groupId = data.groupId;
      console.log(self.groupId);
      self.groupId = data.groupId;
      var model = self.allSubGroups.filter(function (myId) {
        return data.groupId == myId.groupId;
      });
      self.model = [];
      console.log(model);
      self.selectedSubGroups = model;
    },

    updatedSubGroups(data) {
      var self = this;
      self.subGroupId = data.subGroupId;
      console.log("self subgroup id::", self.subGroupId);
      self.subGroupId = data.subGroupId;
      this.selectedSubGroupId = self.subGroupId;
      console.log("selected values", this.selectedSubGroupId);
      var model = self.allBriefNames.filter(function (myId) {
        return data.subGroupId == myId.subGroupId;
      });
      self.model = [];
      console.log("subgroups", model);
      self.selectedBriefNames = model;
      var modeldepartments = self.allDepartments.filter(function (mydepId) {
        return data.subGroupId == mydepId.subGroupId;
      });
      self.modeldepartments = [];
      console.log(modeldepartments);
      self.selectedDepartments = modeldepartments;
    },

    updatedBriefNames(data) {
      var self = this;
      self.briefId = data.briefId;
      console.log(self.briefId);
      self.briefId = data.briefId;
      var model = self.allBriefTopics.filter(function (myId) {
        return data.briefId == myId.briefId;
      });
      self.model = [];
      console.log(model);
      self.selectedBriefTopics = model;
      console.log("brief topics::::",self.selectedBriefTopics)
      // console.log("selected value*****", this.selectedSubGroupId);
      // console.log("selected value", this.selectedSubGroupId);
    },

    imageUrl(imageId) {
      console.log(imageId);
      return process.env.VUE_APP_API_HOST + "/emapp/file/" + imageId;
    },

    removeimage() {
      this.briefing.imageId = "";
    },

    uploadImage(files, event) {
      const URL = process.env.VUE_APP_API_HOST + "/emapp/file/upload";
      console.log("uploadImage", URL);
      console.log("files", files);
      console.log("event", event);
      let fdata = new FormData();
      fdata.append("file", files[0]);

      fdata.append("userId", "uid");
      //fdata.append("briefingId","imageId");

      fetch(URL, {
        method: "POST",

        body: fdata,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("image upload response > ", data);
          // this.service.image = data.data.fileId;

          this.briefing.imageId = data.data.fileId;
          // this.service.imageId=data.data.fileId;
          //this.service.image=this.service.imageId
          // console.log(service.imageId);
        });
    },

    Save() {
      this.$v.briefing.$touch();
      console.log("viewSelected brief topics", this.viewselectedBriefTopics);

      if (this.$v.briefing.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.briefing));
       this.errors = [];
	  		 var errorFlag = false;
        if(this.briefing.officerId == undefined || this.briefing.officerId.length == ""){
               errorFlag = true;
	       		    this.errors.push({"field":"uid","error":" please select atleast one officer"}); 
	       	 	 }
             else {
              errorFlag=false
             }
           
      } else {
        
        if(this.viewselectedBriefTopics.length===undefined  ){
        this.briefing.selectedTopics[this.i].topicId=this.viewselectedBriefTopics
        
        }
                
        // console.log("this.briefing ::::", JSON.stringify(this.briefing));

        this.apiPostSecure(
          process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/briefing",
          this.briefing
        ).then((data) => {
          console.log("post values", data);
          this.$router.push({ name: "Briefings" });
        });
        // this.apiPostSecure(
        //   process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/briefing",
        //   this.briefing
        // ).then((briefing) => {
        //   //    console.log(JSON.stringify(briefing));

        //   this.$router.push({
        //     name: "Briefings",
        //   });
        // });
      }
    },
    getGroups() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/groups"
      )
        .then((response) => response.json())
        .then((data) => (this.allGroups = data));
    },
    getSubGroups() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/subGroups"
      )
        .then((response) => response.json())
        .then((data) => (this.allSubGroups = data));
    },
    getBriefNames() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/BriefNames"
      )
        .then((response) => response.json())
        .then((data) => (this.allBriefNames = data));
    },
    getBriefTopics() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/topics"
      )
        .then((response) => response.json())
        .then((data) => (this.allBriefTopics = data));
    },
    getDepartments() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/departments"
      )
        .then((response) => response.json())
        .then((data) =>
          ((this.allDepartments = data),
            console.log("all department users", this.allDepartments)
          )
        );
    },

    topicdetails({ topicName }) {
      return `${topicName}`;
      //return `${couponName} | ${couponCode} `;
    },
    officerdetails({fullName }) {
     
      return `${fullName}`
    },
   
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/briefing/" +
          this.briefingId
      )
        .then((response) => response.json())
        // .then((data) => (this.briefing = data));
        .then((data) => {
          this.briefing = data;
          console.log("briefing:::",this.briefing)
          console.log("briefing name::",this.briefing.briefName);
          console.log("other brief name:::",this.briefing.otherBriefName)
          data.selectedTopics.forEach((element) => {
            this.viewselectedBriefTopics.push(element.topicId);

            console.log("a")
          });

          
        });
    },

    // getBriefings() {
    //   fetch(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/topics")
    //     .then((response) => response.json())
    //     .then((data) => (this.allTopics = data));
    // },

    getOfficers() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/all/users?authorityType=1"
      )
        .then((response) => response.json())
        .then((data) => (
          this.allOfficers = data
          // console.log("all officers",this.allOfficers)
          // this.fullName=this.allOfficers.firstName,
          // console.log("full name",this.fullName)
          ));
    },
  },
  mounted() {
    //this.getBriefings();
    this.getOfficers();
    this.getGroups();
    this.getSubGroups();
    this.getBriefNames();
    this.getBriefTopics();
    this.getDepartments();
    // this.officerdetails();
  },
};
</script>

<style>
.p-error {
  margin-left: 12rem;
}
</style>